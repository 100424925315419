import { NgModule } from '@angular/core';
import { MinervaButtonComponent } from '@app/payrolls/payroll-chat-bot/minerva-button/minerva-button.component';
import { PayrollChatBotComponent } from '@app/payrolls/payroll-chat-bot/payroll-chat-bot.component';
import { SharedModule } from '@app/shared.module';

@NgModule({
  declarations: [MinervaButtonComponent, PayrollChatBotComponent],
  imports: [SharedModule],
  exports: [MinervaButtonComponent]
})
export class PayrollChatBotModule {}
